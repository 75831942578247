import React, { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
// import localforage from 'localforage';
import { auth, db } from '../firebase';
import { getAccount } from '../hooks/mutations';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
}

export const AuthProvider = ({children}) => {
  // PERSISTANT STATE
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [location, setLocation] = useState('home');
  const [hash, setHash] = useState('home');
  const [nav, setNav] = useState({
    height: 0
  })
  // console.log('user', user);

  // Reset all state from local storage
  useEffect(() => {
    const localUser = JSON.parse(window.localStorage.getItem('user'));
    const localAccount = JSON.parse(window.localStorage.getItem('account'));
    if (localUser) setUser(localUser);
    if (localAccount) setAccount(localAccount);
  }, [])

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/auth.user
  //     // const uid = user.uid;
  //     setUser(user);

  //   } else {
  //     // User is signed out
  //     setUser(null);
  //   }
  // });

  // const getCurrentAccount = async (userId) => {
  //   const currentAccount = await getAccount(userId);
  //   setAccount(currentAccount);
  //   window.localStorage.setItem('account', JSON.stringify(currentAccount));
  // }
  // console.log('account', account);

  useEffect(() => {
    if (user?.uid) {
      try {
        const docRef = doc(db, 'users', user.uid);
        onSnapshot(docRef, (doc) => {
          setAccount({
            ...doc.data(),
            id: doc.id,
            // initials:`${doc.data().firstName.split('')[0]}${doc.data().lastName.split('')[0]}`
          })
        })
        
      } catch (error) {
        console.log('Error getting single user', error);
      }
      window.localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user?.uid])

  useEffect(() => {
    if (account) {
      // getCurrentAccount();
      window.localStorage.setItem('account', JSON.stringify(account));
    }
  }, [account])

  return (
    <>
      <authContext.Provider
        value={{
          account,
          user,
          setUser,
          location,
          setLocation,
          nav,
          setNav,
          hash,
          setHash
        }}
      >
        {children}
      </authContext.Provider>
    </>
  )
}