import { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { adminRoutes, guestRoutes, userRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { useAuth } from './context/authContext';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import './App.css';


function App() {
  const guestContent = useRoutes(guestRoutes);
  const userContent = useRoutes(userRoutes);
  const adminContent = useRoutes(adminRoutes);
  const authContext = useAuth();
  const user = authContext.user;
  const account = authContext.account;
  const admin = authContext.account?.role === "admin";
  const auth = getAuth();
  // const [user, setUser] = useState(null);

  console.log('admin', admin);

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/auth.user
  //     const uid = user.uid;
  //     setUser(user);

  //   } else {
  //     // User is signed out
  //     setUser(null);
  //   }
  // });


  return (
    <ThemeProvider theme={theme}>
      {
        account 
          ? admin
            ? adminContent
            : userContent
          : guestContent
      }
    </ThemeProvider>
  );
}

export default App;
