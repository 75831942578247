import { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { addDoc, collection, deleteDoc, doc, getDoc, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";


//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// FORMS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createForm = async (obj) => {
  try {
      const formRef = collection(db, 'forms');
      const formPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newForm = await addDoc(formRef, formPayload);
      return newForm.id;

  }

  catch (error) {
      console.log('Error creating form', error);
  }
}

export const updateForm = async (obj) => {
  try {
      const formRef = doc(db, 'forms', obj.id);
      const formPayload = {
        ...obj,
        updatedAt: serverTimestamp()
      }

      const updatedForm = await setDoc(formRef, formPayload, {merge:true});
      return 'success';

  }

  catch (error) {
      console.log('Error updating form', error);
  }
}

export const updateFormTransaction = async (field, obj) => {
  try {
      const formRef = doc(db, 'forms', obj.id);
      const updatedForm = await runTransaction(db, async (transaction) => {
        const activeFormDoc = await transaction.get(formRef);
        if (!activeFormDoc.exists()) {
          throw "Document does not exist!";
        }
        //logic
        let newFormDoc = updatedForm.data();
        // console.log('newFormDoc', newFormDoc)

        let fieldArray = newFormDoc[field] ? [...newFormDoc[field]] : [];
        // console.log("fieldArray", fieldArray);

        fieldArray.push(obj[field]);
        // console.log("fieldArray after push", fieldArray);

        newFormDoc[field] = fieldArray;
        // console.log("newFormDoc after update", newFormDoc);

        // Send to firestore
        transaction.set(
          formRef,
          { 
            [field]: fieldArray,
            
          },
          {
            merge: true
          }
        );
  
      });

      return 'success';

  }

  catch (error) {
      console.log('Error updating form', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteForm = async (obj) => {
  try {
    const formRef = doc(db, 'deletedForms', obj.id);
    const formPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newForm = await setDoc(formRef, formPayload);
    await deleteDoc(doc(db, 'forms', obj.id));


  }

  catch (error) {
      console.log('Error deleting form', error);
  }
}



// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetForms = () => {
  const [forms, setForms] = useState();
  const authContext = useAuth();

  const getForms = async () => {
    try {
      const collectionRef = collection(db, 'forms');
      const q = query(collectionRef, where('author', '==', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setForms(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
      getForms()
  }, [])

  return forms ? forms : null

}

// GET SINGLE ATTACHMENT
export const useGetForm = (formId) => {
  const [form, setForm] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getForm = async () => {
    try {
      const docRef = doc(db, 'forms', formId);
      onSnapshot(docRef, (doc) => {
        setForm({...doc.data(), id: doc.id})
        // console.log('doc data', doc.data())
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setForm({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setForm(null)
      // }
      
    } catch (error) {
      console.log('Error getting single form', error);
    }
  }

  useEffect(() => {
    if (formId) {
      getForm()
    }
    
  }, [formId])


  return form ? form : null

}











































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// SUBMISSIONS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createSubmission = async (folder, obj) => {

  if (obj.id) {
    try {
        const submissionRef = doc(db, `submissions-${folder}`, obj.id);
        const submissionPayload = {
          ...obj,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }

        const newSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
        return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }
  } else {
    try {
      const submissionRef = collection(db, `submissions-${folder}`);
      const submissionPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      const newSubmission = await addDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }

  }
}

export const updateSubmission = async (folder, obj) => {
  try {
      const submissionRef = doc(db, `submissions-${folder}`, obj.id);
      const submissionPayload = {
        ...obj,
        updatedAt: serverTimestamp(),
      }

      const updatedSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteSubmission = async (folder, obj) => {
  try {
    const submissionRef = doc(db, 'deletedSubmissions', obj.id);
    const submissionPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newSubmission = await setDoc(submissionRef, submissionPayload);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}

export const archiveSubmissionForConsidering = async (folder, obj) => {
  console.log('folder:', folder, 'obj', obj);
  try {
    const consideringRef = doc(db, 'considering', obj.id);
    const consideringPayload = {
      ...obj,
      voteUp: [obj.email],
      voteDown: [],
      consideredAt: serverTimestamp(),
    }

    const newConsidering = await setDoc(consideringRef, consideringPayload);
    console.log('newConsidering', newConsidering);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));

  }

  catch (error) {
      console.log('Error archiving submission', error);
  }
}




// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET SUBMISSIONS OF A SPECIFIC FORM (FOLDER)
export const useGetSubmissions = (folder) => {
  const [submissions, setSubmissions] = useState();
  const authContext = useAuth();

  const getSubmissions = async () => {
    try {
      const collectionRef = collection(db, `submissions-${folder}`);
      const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setSubmissions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting submissions', error);
    }
  }

  useEffect(() => {
      getSubmissions()
  }, [])

  return submissions ? submissions : null

}

// GET SINGLE ATTACHMENT
export const useGetSubmission = (folder, submissionId) => {
  const [submission, setSubmission] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getSubmission = async () => {
    try {
      const docRef = doc(db, `submissions-${folder}`, submissionId);
      onSnapshot(docRef, (doc) => {
        setSubmission({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setSubmission({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setSubmission(null)
      // }
      
    } catch (error) {
      console.log('Error getting single submission', error);
    }
  }

  useEffect(() => {
    if (submissionId) {
      getSubmission()
    }
    
  }, [submissionId])


  return submission ? submission : null

}


















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// CONSIDERING
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createConsidering = async (obj) => {

  if (obj.id) {
    try {
        const consideringRef = doc(db, 'considering', obj.id);
        const consideringPayload = {
          ...obj,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }

        const newConsidering = await setDoc(consideringRef, consideringPayload, {merge:true});
        return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }
  } 
}

export const updateConsidering = async (obj) => {
  try {
      const consideringRef = doc(db, 'considering', obj.id);
      const consideringPayload = {
        ...obj,
        updatedAt: serverTimestamp(),
      }

      const updatedConsidering = await setDoc(consideringRef, consideringPayload, {merge:true});
      return "success"

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteConsidering = async (obj) => {
  try {
    const consideringRef = doc(db, 'deletedConsidering', obj.id);
    const consideringPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newConsidering = await setDoc(consideringRef, consideringPayload);
    await deleteDoc(doc(db, 'considering', obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}



// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetConsiderings = () => {
  const [considerings, setConsiderings] = useState();
  const authContext = useAuth();

  const getConsiderings = async (folder) => {
    try {
      const collectionRef = collection(db, 'considering');
      // const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setConsiderings(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting considerings', error);
    }
  }

  useEffect(() => {
      getConsiderings()
  }, [])


  return considerings ? considerings : null

}

// GET SINGLE ATTACHMENT
export const useGetConsidering = (consideringId) => {
  const [considering, setConsidering] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getConsidering = async () => {
    try {
      const docRef = doc(db, 'considering', consideringId);
      onSnapshot(docRef, (doc) => {
        setConsidering({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setConsidering({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setConsidering(null)
      // }
      
    } catch (error) {
      console.log('Error getting single considering', error);
    }
  }

  useEffect(() => {
    if (consideringId) {
      getConsidering()
    }
    
  }, [consideringId])


  return considering ? considering : null

}



















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// VALIDATING
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createValidating = async (obj) => {

  if (obj.id) {
    try {
        const validatingRef = doc(db, 'validating', obj.id);
        const validatingPayload = {
          ...obj,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }

        const newValidating = await setDoc(validatingRef, validatingPayload, {merge:true});
        return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }
  } 
}

export const updateValidating = async (obj) => {
  try {
      const validatingRef = doc(db, 'validating', obj.id);
      const validatingPayload = {
        ...obj,
        updatedAt: serverTimestamp(),
      }

      const updatedValidating = await setDoc(validatingRef, validatingPayload, {merge:true});
      return "success"

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteValidating = async (obj) => {
  try {
    const validatingRef = doc(db, 'deletedValidating', obj.id);
    const validatingPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newValidating = await setDoc(validatingRef, validatingPayload);
    await deleteDoc(doc(db, 'validating', obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}



// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetValidatings = () => {
  const [validatings, setValidatings] = useState();
  const authContext = useAuth();

  const getValidatings = async (folder) => {
    try {
      const collectionRef = collection(db, 'validating');
      // const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setValidatings(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting validatings', error);
    }
  }

  useEffect(() => {
      getValidatings()
  }, [])


  return validatings ? validatings : null

}

// GET SINGLE ATTACHMENT
export const useGetValidating = (validatingId) => {
  const [validating, setValidating] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getValidating = async () => {
    try {
      const docRef = doc(db, 'validating', validatingId);
      onSnapshot(docRef, (doc) => {
        setValidating({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setValidating({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setValidating(null)
      // }
      
    } catch (error) {
      console.log('Error getting single validating', error);
    }
  }

  useEffect(() => {
    if (validatingId) {
      getValidating()
    }
    
  }, [validatingId])


  return validating ? validating : null

}



















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// ACCOUNTS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createAccount = async (userId, obj) => {
  try {
      const accountRef = doc(db, 'users', userId);
      const accountPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newAccount = await setDoc(accountRef, accountPayload, {merge:true});

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}

export const updateAccount = async (obj) => {
  try {
      const accountRef = doc(db, 'users', obj.id);
      const accountPayload = {
        ...obj,
        updatedAt: serverTimestamp()
      }

      const updatedAccount = await setDoc(accountRef, accountPayload, {merge:true});

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteAccount = async (obj) => {
  try {
    const accountRef = doc(db, 'deleted', obj.id);
    const accountPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newAccount = await setDoc(accountRef, accountPayload);
    await deleteDoc(doc(db, 'users', obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}



// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET USERS
export const useGetAccounts = () => {
  const [accounts, setAccounts] = useState();
  const authContext = useAuth();

  const getAccounts = async () => {
    try {
      const collectionRef = collection(db, 'users');
      const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setAccounts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting accounts', error);
    }
  }

  useEffect(() => {
      getAccounts()
  }, [])


  return accounts ? accounts : null

}

// GET SINGLE USER HOOK
export const useGetAccount = (userId) => {
  const [account, setAccount] = useState();
  const authContext = useAuth();

  // GET SINGLE USER
  const getAccount = async () => {
    try {
      const docRef = doc(db, 'users', userId);
      onSnapshot(docRef, (doc) => {
        setAccount({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setAccount({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setAccount(null)
      // }
      
    } catch (error) {
      console.log('Error getting single account', error);
    }
  }

  useEffect(() => {
    if (userId) {
      getAccount()
    }
    
  }, [userId])


  return account ? account : null

}

// GET SINGLE USER
export const getAccount = (userId) => {
  try {
    const docRef = doc(db, 'users', userId);
    onSnapshot(docRef, (doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        initials:`${doc.data().firstName.split('')[0]}${doc.data().lastName.split('')[0]}`
      }
    })
    
  } catch (error) {
    console.log('Error getting single user', error);
  }
}

