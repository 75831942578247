import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// EVERYONE ROUTES
const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const FormDisplayStartCollectPage = Loadable(lazy(() => import('./pages/FormDisplayStartCollectPage')));
const FormBuilder1Page = Loadable(lazy(() => import('./pages/FormBuilder1Page')));
const NotFoundPage = Loadable(lazy(() => import('./pages/NotFoundPage')));

// USER ROUTES
const DashboardPage = Loadable(lazy(() => import('./pages/DashboardPage')));

export const guestRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'about', element: <LandingPage /> },
  { path: 'process', element: <LandingPage /> },
  { path: 'get-involved', element: <LandingPage /> },
  { path: 'contact', element: <LandingPage /> },
  { path: 'current', element: <LandingPage /> },
  { path: 'current-status', element: <LandingPage /> },
  { path: 'current-status/:statusId', element: <LandingPage /> },
  { path: 'form-start/:formId', element: <LandingPage /> },
  { path: 'form-end/:formId', element: <LandingPage /> },
  {
    path: '/*', element: <NotFoundPage />
  }
];

export const userRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'about', element: <LandingPage /> },
  { path: 'process', element: <LandingPage /> },
  { path: 'get-involved', element: <LandingPage /> },
  { path: 'contact', element: <LandingPage /> },
  { path: 'current', element: <LandingPage /> },
  { path: 'current-status', element: <LandingPage /> },
  { path: 'current-status/:statusId', element: <LandingPage /> },
  { path: 'form-start/:formId', element: <LandingPage /> },
  { path: 'form-end/:formId', element: <LandingPage /> },
  {
    path: '/*', element: <NotFoundPage />
  }
];

export const adminRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'dashboard', element: <DashboardPage /> },
  { path: 'dashboard/forms', element: <DashboardPage /> },
  { path: 'dashboard/create-form', element: <DashboardPage /> },
  { path: 'dashboard/update-form/:formId', element: <DashboardPage /> },
  { path: 'dashboard/forms/:formId', element: <DashboardPage /> },
  { path: 'dashboard/submissions', element: <DashboardPage /> },
  { path: 'dashboard/submissions/:formId', element: <DashboardPage /> },
  { path: 'about', element: <LandingPage /> },
  { path: 'process', element: <LandingPage /> },
  { path: 'get-involved', element: <LandingPage /> },
  { path: 'contact', element: <LandingPage /> },
  { path: 'current', element: <LandingPage /> },
  { path: 'current-status', element: <LandingPage /> },
  { path: 'current-status/:statusId', element: <LandingPage /> },
  { path: 'form-start/:formId', element: <LandingPage /> },
  { path: 'form-end/:formId', element: <LandingPage /> },
  { path: 'create-form-1', element: <FormBuilder1Page /> },
  {
    // path: '/*', element: <NotFoundPage />
  }
];