import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette:{
    primary:{
      main: '#0A5FA5',
      dark: '#05337C',
      light: '#ADDCF0'
    },
    secondary:{
      main: '#D39090',
      dark: '#C16F6F',
      light: '#E2ACB2'
    },
    alt:{
      main: '#ABABDD',
      dark: '#8890D6',
      light: '#C9BADD'
    },
    verify:{
      main: '#159B78',
      dark: '#057A69',
      light: '#40C69F'
    },
    blank:{
      main:'#FFFFFF',
      dark:'#CCCCCC'
    },
    background: {
      main: '#FFFFFF',
      secondary: '#F7F7F7',
      dark: '#03032E'
    },
  },
  typography: {
    fontFamily:'Poppins',
    fontSize: 16,
    default: {
      fontFamily:'DM Sans',
      fontSize:20,
      margin:0,
      color:'#777777'
    },
    h1: {
      textTransform:'uppercase',
      fontSize:'clamp(2.5rem, 6vw, 6.85rem)',
      fontWeight:800,
      lineHeight:1,
      wordWrap:'break-word',
      hyphens:'auto'
    }
  },
  
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: 'calc(100%-64px)',
          background: '#ffffffee',
          padding: 32
        },
      },
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position:'fixed',
            top:0,
          },
        },
      ]
    },

    MuiGrid: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position: 'fixed',
            top: 0,
          },
        },
        {
          props: { variant: 'centerWidth' },
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems:'flex-start'
          },
        },
        {
          props: { variant: 'centerHeight' }, 
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        },
        {
          props: { variant: 'center' },
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'space-between' },
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center'
          },
        },
      ],
    },

    MuiButton: {
    },
    
    MuiDrawerHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        title: {
          fontWeight: 'bold',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '16px 0',
          '& .MuiInputBase-input': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    footer: 200,
    nav: 100,
    navSticky:50
  },

  breakpoint:{
    xs:380,
    sm:640,
    md:1024,
    lg:1400,
    xl:1920
  }
})






// ALL MUI COMPONENTS
// MuiAppBar
// MuiAvatar
// MuiBackdrop
// MuiBadge
// MuiBottomNavigation
// MuiButton
// MuiButtonBase
// MuiCard
// MuiCardActions
// MuiCardContent
// MuiCardHeader
// MuiCardMedia
// MuiCheckbox
// MuiChip
// MuiCircularProgress
// MuiCollapse
// MuiContainer
// MuiCssBaseline
// MuiDialog
// MuiDialogActions
// MuiDialogContent
// MuiDialogContentText
// MuiDialogTitle
// MuiDivider
// MuiDrawer
// MuiFab
// MuiFilledInput
// MuiFormControl
// MuiFormControlLabel
// MuiFormGroup
// MuiFormHelperText
// MuiFormLabel
// MuiGrid
// MuiIconButton
// MuiInput
// MuiInputAdornment
// MuiInputBase
// MuiInputLabel
// MuiLinearProgress
// MuiLink
// MuiList
// MuiListItem
// MuiListItemAvatar
// MuiListItemIcon
// MuiListItemSecondaryAction
// MuiListItemText
// MuiMenu
// MuiMenuItem
// MuiMobileStepper
// MuiModal
// MuiNativeSelect
// MuiOutlinedInput
// MuiPaper
// MuiPopover
// MuiRadio
// MuiScopedCssBaseline
// MuiSelect
// MuiSkeleton
// MuiSlider
// MuiSnackbar
// MuiSnackbarContent
// MuiSpeedDial
// MuiSpeedDialAction
// MuiSpeedDialIcon
// MuiStep
// MuiStepButton
// MuiStepConnector
// MuiStepContent
// MuiStepIcon
// MuiStepLabel
// MuiStepper
// MuiSvgIcon
// MuiSwitch
// MuiTab
// MuiTable
// MuiTableBody
// MuiTableCell
// MuiTableContainer
// MuiTableFooter
// MuiTableHead
// MuiTablePagination
// MuiTableRow
// MuiTableSortLabel
// MuiTabs
// MuiTextareaAutosize
// MuiTextField
// MuiToggleButton
// MuiToggleButtonGroup
// MuiToolbar
// MuiTooltip
// MuiTouchRipple
// MuiTypography
// MuiUseMediaQuery
// MuiZoom